import { useLoginProfile } from '@/auth/useLoginProfile'
import Link from '@/components/Link'
import { mainLayoutConstants } from '@/lib/main-layout-constants'
import { isCurrentMenu, ISection, menus as SIDE_MENU_LIST } from '@/lib/side-menu-list'
import useStore from '@/store/useStore'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Box, Divider, IconButton, List, ListSubheader, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import * as React from 'react'
import { DrawerHeader } from '../DrawerHeader'
import { useScroll } from './components/ScrollProvider'
import SectionMenu from './components/SectionMenu'
import SideMenuItem from './components/SideMenuItem'
import { rootSx } from './style'

const { sidemenuDividerColor } = mainLayoutConstants

function Sidebar() {
    const router = useRouter()
    const { sidebarStore } = useStore()
    const pathname = router.pathname

    const { scrollPosition, setScrollPosition } = useScroll()

    const ref = React.useRef<HTMLElement>(null)

    React.useEffect(() => {
        // 페이지가 로딩될 때 스크롤 위치를 복원
        if (!ref.current) return
        console.log(scrollPosition)
        ref.current.scrollTop = scrollPosition
    }, [])

    const handleScroll = () => {
        // 스크롤 이벤트 핸들러에서 현재 스크롤 위치를 출력
        if (!ref.current) return
        setScrollPosition(ref.current.scrollTop)
    }

    const handleClickLink = (href: string) => (e: React.MouseEvent) => {
        e.preventDefault()
        router.push(href)
    }
    const login = useLoginProfile()

    const loginUserIsMaster = login?.profile.navId === '2'

    return (
        <Box sx={rootSx} className="Sidebar-root">
            {/* {loginUserIsMaster + ''} */}
            <DrawerHeader sx={{ pl: 2, color: '#fff' }}>
                <Typography variant="h6" sx={{ fontSize: '1.2rem', flex: 1 }}>
                    <Link href="/" sx={{ color: 'inherit', textDecoration: 'none' }}>
                        Documents
                    </Link>
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        sidebarStore.setOpened(false)
                    }}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Box ref={ref} onScroll={handleScroll} flex={1} sx={{ overflow: 'auto' }} className="Sidebar-body">
                <List component="nav" className="Sidebar-list">
                    <Divider color={sidemenuDividerColor} />
                    {SIDE_MENU_LIST.filter((el) => {
                        if (el.type === 'menu') {
                            if (loginUserIsMaster) {
                                return true
                            } else {
                                return !el.isMaster
                            }
                        }
                        return true
                    }).map((item, idx) => {
                        if (item.type === 'menu') {
                            return (
                                <SideMenuItem
                                    key={item.id}
                                    icon={item.icon}
                                    title={item.title}
                                    active={isCurrentMenu(item.href, pathname, item.match)}
                                    // active={menuManager.isMenuPathMatched(item, currentUrl)}
                                    onClick={handleClickLink(item.href)}
                                />
                            )
                        }

                        if (item.type === 'divider') {
                            if (!loginUserIsMaster) {
                                if (item.isMaster) {
                                    return <></>
                                }
                            }
                            return <Divider key={item.id} color={sidemenuDividerColor} />
                        }

                        if (item.type === 'label') {
                            if (!loginUserIsMaster) {
                                if (item.isMaster) {
                                    return <></>
                                }
                            }
                            return (
                                <ListSubheader key={item.id} className="Sidebar-listSubHeader">
                                    {item.title}
                                </ListSubheader>
                            )
                        }

                        if (item.type === 'section') {
                            const section = item as ISection

                            const subMenusItem = item.submenus.filter((el) => {
                                if (el.type === 'menu') {
                                    if (loginUserIsMaster) {
                                        return true
                                    } else {
                                        return !el.isMaster
                                    }
                                }
                                return true
                            })
                            if (subMenusItem.length < 1) return <></>

                            return (
                                <>
                                    <SectionMenu
                                        key={idx}
                                        sectionClassName="Sidebar-section"
                                        // submenuBoxClassName={classes.submenuBox}
                                        section={section}
                                        subMenus={subMenusItem}
                                        currentHref={router.pathname}
                                        expanded={sidebarStore.expandedSectionIds.includes(section.sectionId)}
                                        onLinkClick={handleClickLink}
                                        onSectionClick={() => sidebarStore.toggleExpandSection(section.sectionId)}
                                    />
                                </>
                            )
                        }
                        // return undefined
                        return <div>{JSON.stringify(item)}</div>
                    })}
                </List>
            </Box>
        </Box>
    )
}

export default observer(Sidebar)
