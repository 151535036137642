/* eslint-disable react/no-array-index-key */
import { IDivider, IMenu, isCurrentMenu, ISection } from '@/lib/side-menu-list'
import { Box, Collapse, Divider, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SideMenuItem from './SideMenuItem'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { mainLayoutConstants } from '@/lib/main-layout-constants'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { UserProfile } from '@local/domain'
import { useLoginProfile } from '@/auth/useLoginProfile'

type Props = {
    section: ISection
    expanded: boolean
    sectionClassName?: string
    // submenuBoxClassName?: string
    subMenus: (IDivider | IMenu)[]
    currentHref?: string
    onSectionClick?: (event: React.MouseEvent) => void
    onLinkClick: (href: string) => (e: React.MouseEvent) => void
}

const { sidemenuFgColorActive, sidemenuFgColor } = mainLayoutConstants

export default function SectionMenu(props: Props) {
    const {
        section,
        onSectionClick,
        expanded = false,
        sectionClassName,
        // submenuBoxClassName,
        currentHref,
        onLinkClick,
        subMenus,
    } = props

    return (
        <Box>
            <ListItemButton
                className={clsx(sectionClassName, expanded && 'Mui-active')}
                sx={{
                    // display: login?.profile.userId === 1 ? '' : displayNone,
                    pr: 2,
                    '& .MuiListItemIcon-root': {
                        color: sidemenuFgColor,
                        // minWidth: 40,
                    },
                    '&.Mui-active': {
                        color: sidemenuFgColorActive,
                        '& .MuiListItemIcon-root': {
                            color: sidemenuFgColorActive,
                        },
                        '& .sectionIndicator': {
                            display: 'flex',
                            alignItems: 'center',
                            color: sidemenuFgColorActive,
                        },
                    },
                    '& .sectionIndicator': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
                onClick={onSectionClick}
                disableGutters
            >
                {section.icon && <ListItemIcon>{section.icon}</ListItemIcon>}
                <ListItemText primary={section.title} classes={{ primary: 'title' }} />
                <Icon className="sectionIndicator">{expanded ? <ExpandLess /> : <ExpandMore />}</Icon>
            </ListItemButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {subMenus.map((menu, idx) => {
                        if (menu.type === 'divider') {
                            return <Divider key={idx} />
                        }
                        return (
                            <Box sx={{ ml: 3.5, borderLeft: '1px solid #E0E0E0' }} key={menu.sectionId}>
                                <SideMenuItem
                                    key={menu.href + idx}
                                    title={menu.title}
                                    active={isCurrentMenu(menu.href, currentHref, menu.match)}
                                    className="submenu"
                                    onClick={onLinkClick(menu.href)}
                                />
                            </Box>
                        )
                    })}
                </List>
            </Collapse>
        </Box>
    )
}
