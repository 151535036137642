import CustomBreadcrumbs, { CustomBreadcrumbsProps } from '@/components/CustomBreadcrumbs'
import { mainLayoutConstants } from '@/lib/main-layout-constants'
import { flatSx } from '@/lib/sx-props'
import useStore from '@/store/useStore'
import { Box, Divider, Drawer, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React from 'react'
import { DrawerHeader } from './components/DrawerHeader'
import Sidebar from './components/Sidebar'
import SidebarAutoOpenCheck from './components/SidebarAutoOpenCheck'
import Topbar from './components/Topbar'
import { rootSx } from './style'
import RedirectOnLogout from '@/components/RedirectOnLogout'
import { ScrollProvider } from './components/Sidebar/components/ScrollProvider'

const {
    sidemenuWidth,
    sidemenuBgColor,
    contentBgColor,
    contentPaddingBottom: CONTENT_PADDING_BOTTOM,
} = mainLayoutConstants

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'contentShift',
})<{
    contentShift: number
}>(({ theme, contentShift }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: contentBgColor,
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: contentShift,
    ...(contentShift !== 0 && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(contentShift === 0 && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

type Props = {
    sx?: SxProps<Theme>
    className?: string
    title?: React.ReactNode
    children?: React.ReactElement
    breadcrumbProps?: CustomBreadcrumbsProps
    contentPaddingBottom?: number
}

function MainLayout(props: Props) {
    const { sx, className, title, breadcrumbProps, contentPaddingBottom = CONTENT_PADDING_BOTTOM, children } = props
    const theme = useTheme()
    const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
    const { sidebarStore } = useStore()
    const isSidebarOpen = sidebarStore.opened
    const contentShift = smOrDown ? 0 : isSidebarOpen ? sidemenuWidth : 0

    return (
        // <RedirectOnLogout>

        <Box className={clsx('MainLayout-root', className)} sx={flatSx(rootSx, sx)}>
            <SidebarAutoOpenCheck />
            <Topbar isSidebarOpen={isSidebarOpen} title={title} onClickMenuButton={() => sidebarStore.toggleOpen()} />
            <Drawer
                open={isSidebarOpen}
                sx={{
                    width: sidemenuWidth,
                    '& .MuiDrawer-paper': {
                        width: sidemenuWidth,
                        boxSizing: 'border-box',
                        backgroundColor: sidemenuBgColor,
                        color: 'white',
                    },
                }}
                variant={smOrDown ? 'temporary' : 'persistent'}
                anchor="left"
                onClose={() => sidebarStore.setOpened(false)}
            >
                <Divider />

                <Sidebar />
            </Drawer>

            <Main
                contentShift={contentShift}
                className="MainLayout-bodyContainer"
                style={{
                    paddingBottom: `${contentPaddingBottom}px`,
                }}
            >
                <DrawerHeader />
                {breadcrumbProps && <CustomBreadcrumbs {...breadcrumbProps} />}
                <Box
                    className="MainLayout-body"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Main>
        </Box>
        // </RedirectOnLogout>
    )
}

export default observer(MainLayout)
