import { mainLayoutConstants } from '@/lib/main-layout-constants'
import { flatSx } from '@/lib/sx-props'
import { SxProps } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import clsx from 'clsx'
import * as React from 'react'

const { sidemenuFgColorActive, sidemenuFgColor } = mainLayoutConstants

type Props = {
    sx?: SxProps
    className?: string
    icon?: React.ReactNode
    title: string
    active: boolean
    onClick: (event: React.MouseEvent) => void
}

export default function SideMenuItem(props: Props) {
    const { sx, className, icon, title, active, onClick } = props

    return (
        <ListItemButton
            onClick={onClick}
            className={clsx('SideMenuItem-root', className, {
                'Mui-active': active,
            })}
            sx={flatSx(
                {
                    '& .MuiListItemIcon-root': {
                        color: sidemenuFgColor,
                        minWidth: 40,
                    },
                    '&.Mui-active': {
                        color: sidemenuFgColorActive,
                        '& .MuiListItemIcon-root': {
                            color: sidemenuFgColorActive,
                        },
                    },
                },
                sx,
            )}
        >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{title}</ListItemText>
        </ListItemButton>
    )
}
