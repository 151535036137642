import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import ExplicitIcon from '@mui/icons-material/Explicit'

let seq = 0
const nextMenuId = () => `_auto_generated_id_${++seq}`

type MatchFn = (path: string) => boolean

type HasId = {
    id: string
}

export interface IDivider {
    type: 'divider'
    isMaster?: boolean
}

export interface ILabel {
    type: 'label'
    icon?: React.ReactNode
    title: string
    sectionId?: string
    isMaster?: boolean
}

export interface IMenu {
    type: 'menu'
    title: string
    href: string
    icon?: React.ReactNode
    sectionId?: string
    match?: MatchFn
    isMaster?: boolean
}

export interface ISection {
    type: 'section'
    icon?: React.ReactNode
    title: string
    sectionId: string
    submenus: (IDivider | IMenu)[]
    match?: MatchFn
}

const nextSectionId = () => `section${++seq}`

const menusTemp: (ILabel | IDivider | IMenu | ISection)[] = [
    {
        type: 'menu',
        icon: <ExplicitIcon />,
        title: '대시보드',
        href: '/',
    },
    {
        type: 'label',
        title: '백엔드 개발 가이드',
    },
    {
        type: 'divider',
    },
    {
        type: 'menu',
        icon: <DesktopWindowsOutlinedIcon />,
        title: 'zero web 개발 초기 상태 셋팅',
        href: '/doc/zero-web-frist-setting',
        isMaster: true,
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '개발 환경 설정',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '서버 개발 환경 설정',
                href: '/doc/how-to-setup-server-development',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Spring Tolls 4 for Eclipse 설치 가이드',
                href: '/doc/eclipse-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'MobaXterm 설치 가이드',
                href: '/doc/mobaxterm-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'sql Developer 설치 가이드',
                href: '/doc/sql-developer-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'IntelliJ 설치 가이드',
                href: '/doc/intellij-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'JDK 설치 가이드',
                href: '/doc/jdk-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'OpenJDK 1.8 설치 가이드',
                href: '/doc/jdk-installation-intelliJ',
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '개발 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'MyBatis 개발 가이드',
                href: '/doc/how-to-use-mybatis',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Auditing 개발 가이드',
                href: '/doc/how-to-audit',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'WEB SERVER 개발자 가이드',
                href: '/doc/web-server-dev-guide',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '서비스 BackgroundJob 가이드',
                href: '/doc/service-backgroundjob-guide',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'ZERO SECURITY 로그인',
                href: '/doc/how-to-login-zerosecurity',
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '배포 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '서버 자동 빌드 및 배포 가이드',
                href: '/doc/how-to-setup-server-infra',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '깃 미러링 가이드',
                href: '/doc/how-to-use-git-mirroring',
            },            
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '프레임워크 개발 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'library 로컬 테스트 및 배포',
                href: '/doc/framework-local-test-and-build',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'SPRING INTERCEPTOR 이용한 API LOGGING',
                href: '/doc/framework-interceptor-logging',
            },            
        ],   
    },
    {
        type: 'label',
        title: '프론트엔드 개발 가이드',
    },

    { type: 'divider' },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '개발 환경설정',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '웹 개발 환경 설정',
                href: '/doc/how-to-setup-web-development',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'NodeJS 설치 가이드',
                href: '/doc/how-to-setup-nodejs',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'pnpm 설치 및 이용 가이드',
                href: '/doc/how-to-use-pnpm',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Visual Studio Code 설치 가이드',
                href: '/doc/vs-studio-code-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Node.JS 설치 가이드',
                href: '/doc/node-js-installation',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'gitBash  설치 가이드',
                href: '/doc/gitbash-installation',
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '개발 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '컴포넌트 및 페이지 추가',
                href: '/doc/how-to-make-component-and-page',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '다이어로그 생성',
                href: '/doc/how-to-make-dialog',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'API CONECTION',
                href: '/doc/how-to-api-conection',
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '배포 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '프론트 자동배포 가이드',
                href: '/doc/how-to-setup-web-infra-development',
            },
        ],
    },

    {
        type: 'label',
        title: '사용자 메뉴얼',
    },
    {
        type: 'divider',
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '사용자 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'AUDIT,SYSTEM 로그',
                href: '/doc/audit-system-log',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'JOB 리스트/로그',
                href: '/doc/job-list-job-log',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'NAV',
                href: '/doc/nav-mgmt',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '공통코드',
                href: '/doc/common-code',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '권한설정',
                href: '/doc/authority-set',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'META 단어',
                href: '/doc/meta-word',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '사용자',
                href: '/doc/user-mgmt',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '사용자 권한부여',
                href: '/doc/user-authorization',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '컴포넌트 가이드',
                href: '/doc/compont-guide',
            },

            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '페이지 목록',
                href: '/doc/page-list',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '페이지 열기',
                href: '/doc/page-open',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '사용자 권한',
                href: '/doc/user-permission-management',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '에러코드 관리',
                href: '/doc/error-code-mgmt',
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '자료실',
                href: '/doc/document-repository',
            },
        ],
    },

    {
        type: 'label',
        title: '초기 프로젝트 생성 가이드',
        isMaster: true,
    },
    {
        type: 'divider',
        isMaster: true,
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '배포 환경설정 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'JENKINS 설정 가이드',
                href: '/doc/how-to-setup-jenkins',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'JENKINS 설치 가이드',
                href: '/doc/jenkins-install-guide',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Docker 설치 가이드',
                href: '/doc/docker-install-guide',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '프로젝트 CI배포 적용 가이드',
                href: '/doc/how-to-distribution-projectci',
                isMaster: true,
            },

            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'Nexus3 설치 가이드',
                href: '/doc/nexus3-installation',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'NGINX 웹서버 설치',
                href: '/doc/how-to-make-nginx',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'HARBOR 설치 가이드',
                href: '/doc/harbor-install-guide',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'CI/CD 로컬 환경 구성 가이드',
                href: '/doc/ci-cd-make-guide',
                isMaster: true,
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: '개발서버 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'ZERO SERVER PROJECT 구축 가이드',
                href: '/doc/how-to-create-zero-server-project',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'sts 프로젝트 생성 가이드',
                href: '/doc/sts-project-create',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'gitlab설치 가이드',
                href: '/doc/git-lab-installation',
                isMaster: true,
            },
        ],
    },
    {
        type: 'section',
        sectionId: nextSectionId(),
        title: 'Clover 구축 개발 가이드',
        icon: <DesktopWindowsOutlinedIcon />,
        submenus: [
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'react Nextjs 초기 프로젝트 생성 가이드',
                href: '/doc/how-to-make-React-NextJS-project-create',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: '초기 프로젝트에 clover zero-web 소스코드 얹기',
                href: '/doc/how-to-paste-gitsource',
                isMaster: true,
            },
            {
                type: 'menu',
                icon: <DesktopWindowsOutlinedIcon />,
                title: 'zero web 개발 초기 상태 셋팅',
                href: '/doc/zero-web-frist-setting',
                isMaster: true,
            },
        ],
    },
    {
        type: 'label',
        title: '자료실',
    },
    {
        type: 'divider',
    },
    {
        type: 'menu',
        icon: <DesktopWindowsOutlinedIcon />,
        title: '설치파일 자료실',
        href: '/pds/list',
    },

    // {
    //     type: 'label',
    //     title: '기타',
    // },
    //     {
    //         type: 'menu',
    //         icon: <DesktopWindowsOutlinedIcon />,
    //         title: '개발자 PC의 설정',
    //         href: '/doc/how-to-setup-developer-pc',
    //     },
]

export type ISideMenuItem = (ILabel | IDivider | IMenu | ISection) & HasId

const _isCurrentMenu = (menuHref: string, path: string | undefined | null, matchFn?: MatchFn): boolean => {
    // routerPath:/           일때, menuHref:/jobs      path:/faq
    // routerPath:/xyz        일때, menuHref:/xyz/jobs  path:/faq
    if (!path) return false

    if (path === menuHref) return true

    if (menuHref === '/') {
        return path.startsWith(`${menuHref}?`)
    }

    if (matchFn) {
        return matchFn(path)
    }

    return path.startsWith(`${menuHref}/`) || path.startsWith(`${menuHref}?`)
}

const menus: ISideMenuItem[] = menusTemp.map((it) => ({ ...it, id: nextMenuId() }))

export const isCurrentMenu = (menuHref: string, path: string | undefined | null, matchFn?: MatchFn): boolean => {
    const yes = _isCurrentMenu(menuHref, path, matchFn)
    // console.log({ yes: yes ? 'YES' : '', menuHref, path })
    return yes
}

export { menus }
