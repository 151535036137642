import clsx from 'clsx'
import { mainLayoutConstants } from '@/lib/main-layout-constants'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { Button, MenuItem, MenuList, Stack, useMediaQuery, useTheme } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useLoginProfile } from '@/auth/useLoginProfile'
import { useRouter } from 'next/router'

const { sidemenuWidth } = mainLayoutConstants

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

interface AppBarProps extends MuiAppBarProps {
    shift: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'shift',
})<AppBarProps>(({ theme, shift }) => ({
    zIndex: theme.zIndex.drawer + (shift ? 1 : -1),
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#fafafa',
    color: '#000',
    ...(shift && {
        width: `calc(100% - ${sidemenuWidth}px)`,
        marginLeft: sidemenuWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!shift && {
        '& .MuiToolbar-root': {
            paddingLeft: theme.spacing(3.5),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(3),
            },
        },
    }),
}))

interface Props {
    title?: React.ReactNode
    isSidebarOpen: boolean
    className?: string
    onClickMenuButton: () => void
}

export default function Topbar(props: Props) {
    const { className, isSidebarOpen, title, onClickMenuButton } = props
    const theme = useTheme()
    const smOrDown = useMediaQuery(theme.breakpoints.down('md'))
    const shift = !!(isSidebarOpen && !smOrDown)
    const login = useLoginProfile()
    const router = useRouter()
    const onClickLogout = () => {
        router.push('/logout')
    }
    return (
        <AppBar className={clsx('Topbar-root', className)} position="fixed" shift={shift} elevation={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Toolbar sx={{ display: 'flex', height: '100%' }}>
                    <IconButton
                        onClick={onClickMenuButton}
                        aria-label="open drawer"
                        edge="start"
                        sx={{
                            color: '#000',
                            mr: 1,
                        }}
                    >
                        {isSidebarOpen ? <MenuOpenIcon htmlColor="#000" /> : <MenuIcon htmlColor="#000" />}
                    </IconButton>

                    {typeof title === 'string' ? (
                        <Typography
                            variant="subtitle1"
                            noWrap
                            component="div"
                            sx={{
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                color: '#000',
                                transition: '0.5s',
                                fontFamily: 'NanumSquare',
                            }}
                        >
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                </Toolbar>
                <Stack direction="row" alignItems="center" spacing={1} pr={2}>
                    <Typography>{login?.profile.userNm} 님</Typography>
                    <Button
                        onClick={() => {
                            onClickLogout()
                        }}
                    >
                        로그아웃
                    </Button>
                </Stack>
            </Stack>
        </AppBar>
    )
}
