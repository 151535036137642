import { Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

export const DrawerHeader: React.FC<BoxProps> = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))
