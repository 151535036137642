import { mainLayoutConstants } from '@/lib/main-layout-constants'
import { SxProps, alpha } from '@mui/material'

const { sidemenuBgColor, sidemenuFgColor, sidemenuFgColorActive } = mainLayoutConstants

export const rootSx: SxProps = {
    backgroundColor: sidemenuBgColor,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: sidemenuFgColor,
    overflow: 'hidden',
    '& .Sidebar-list': {
        pt: 0,
    },
    '& .Sidebar-listSubHeader': {
        backgroundColor: sidemenuBgColor,
        color: '#ccc',
        fontSize: '0.8rem',
        lineHeight: '1.2rem',
        pt: 3,
        pb: 1,
    },
    '& .Sidebar-section': {
        pl: 2,
    },

    '& .Sidebar-body': {
        // 스크롤바 영역
        '::-webkit-scrollbar': {
            width: 3,
        },
        // 스크롤바 움직이는 자체 그놈
        '::-webkit-scrollbar-thumb': {
            backgroundColor: sidemenuFgColorActive,
            borderRadius: 7,
        },
        // 스크롤바 뒷배경
        '::-webkit-scrollbar-track': {},
    },
}
