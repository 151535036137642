import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
    py: 0,
    px: 1,
    '& .MuiTypography-colorInherit': {
        color: '#999999',
    },

    '& .MuiTypography-colorPrimary': {
        color: '#242424',
    },
}
